const defaultFonts = [
  "Times New Roman",
  "Arial",
  "Courier New",
  "MS Gothic",
  "SimSun",
];

const labelTypes = {
  image: "image",
  text_fields: "text_fields",
  start_date_field: "start_date_field",
  end_date_field: "end_date_field",
  transitional_date: "transitional_date",
  nutritional_fields: "nutritional_fields",
  qr_code: "qr",
  bar_code: "bar",
};
const days = [
  {
    label: "M",
    style: { backgroundColor: "#0078d4", color: "white" },
    value: "Mon",
  },
  {
    label: "Tu",
    style: { backgroundColor: "#f5dd01", color: "black" },
    value: "Tue",
  },
  {
    label: "W",
    style: { backgroundColor: "#d32f2f", color: "white" },
    value: "Wed",
  },
  {
    label: "Th",
    style: { backgroundColor: "#795548", color: "white" },
    value: "Thr",
  },
  {
    label: "F",
    style: { backgroundColor: "#4caf50", color: "white" },
    value: "Fri",
  },
  {
    label: "Sa",
    style: { backgroundColor: "#f57c00", color: "white" },
    value: "Sat",
  },
  {
    label: "Su",
    style: { backgroundColor: "#333333", color: "white" },
    value: "Sun",
  },
];

const dateIncludes = [
  { key: "date", label: "Show date" },
  { key: "time", label: "Show time" },
  { key: "day", label: "Show day of week" },
];

const dateFormat = {
  monthFirst: "mm/dd/yyyy h:mm a",
  dateFirst: "dd/mm/yyyy h:mm a",
};

const dayFormat = {
  ddd: "ddd",
  dddd: "dddd",
};

const positionAttrbutes = {
  position: {
    x: 0,
    y: 0,
  },
  styles: {
    width: 100,
    height: 10,
  },
  input: {
    style: {
      fontSize: "10px",
      fontFamily: defaultFonts?.[0],
      textAlign: "left",
      fontWeight: "normal",
      lineHeight: "20px",
    },
  },
  minWidth: 50,
  minHeigth: 30,
};

const subItemStyle = {
  input: {
    style: {
      fontSize: "6px",
      fontFamily: defaultFonts?.[0],
      textAlign: "left",
      fontWeight: "normal",
    },
    border: { borderBottom: "1px solid #42b03a" },
  },
};

const nutritionalKeys = {
  servingSize: "servingSize",
  calories: "calories",
  dv: "dv",
  serving_per_container: "servingPerContainer",
};

const textFields = [
  {
    label: "Item Name (English)",
    labelText: "Name",
    showLabel: false,
    readOnly: true,
    key: "en_name",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Item Name(English)",
    value: "",
  },
  {
    label: "Item Name (Spanish)",
    labelText: "Name",
    showLabel: false,
    readOnly: true,
    key: "es_name",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Item Name(Spanish)",
    value: "",
  },
  {
    label: "Price",
    labelText: "Price",
    showLabel: false,
    readOnly: true,
    key: "additionalInfo",
    childKey: "sellingPrice",
    ...positionAttrbutes,
    placeholder: "Item Price",
    value: "",
  },
  {
    label: "Ingredients",
    labelText: "Ingredients",
    showLabel: true,
    readOnly: true,
    key: "nutritionalInfo",
    childKey: "ingredients",
    ...positionAttrbutes,
    placeholder: "Ingredients",
    value: "",
  },
  {
    label: "Allergens",
    key: "nutritionalInfo",
    labelText: "Allergens",
    showLabel: true,
    readOnly: true,
    childKey: "allergens",
    ...positionAttrbutes,
    placeholder: "Allergens",
    value: "",
  },
  {
    label: "SKU",
    labelText: "SKU",
    showLabel: true,
    readOnly: true,
    key: "additionalInfo",
    childKey: "sku",
    ...positionAttrbutes,
    placeholder: "SKU",
    value: "",
  },
  {
    label: "Address",
    key: "address",
    childKey: false,
    labelText: "Address",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Address",
    value: "",
  },
  {
    label: "Employee Name",
    key: "user_name",
    childKey: false,
    labelText: "Employee",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Employee Name",
    value: "",
  },
  {
    label: "Prepped By",
    key: "user_name",
    childKey: false,
    labelText: "Prepped By",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Enter Prepped By",
    value: "",
  },
  {
    label: "Username",
    key: "user_name",
    childKey: false,
    labelText: "Username",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Username",
    value: "",
  },
  {
    label: "Description",
    key: "additionalInfo",
    childKey: "description",
    labelText: "Description",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Description",
    value: "",
  },
  {
    label: "Net Weight",
    key: "additionalInfo",
    childKey: "netWeight",
    labelText: "Net Weight",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Net Weight",
    value: "",
  },
  {
    label: "Custom Field",
    key: "additionalInfo",
    childKey: "customFieldValue",
    labelText: "",
    showLabel: true,
    readOnly: true,
    ...positionAttrbutes,
    placeholder: "Custom Field",
    value: "",
  },
];

const startDateFields = [
  {
    label: "Made",
    labelText: "Made",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Made",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Pull",
    labelText: "Pull",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Pull",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Received",
    labelText: "Received",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Received",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Received On",
    labelText: "Received On",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Received On",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Make",
    labelText: "Make",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Make",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Prep",
    labelText: "Prep",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Prep",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Prepared On",
    labelText: "Prepared On",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Prepared On",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Day, Date, Time",
    labelText: "Day, Date, Time",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Day, Date, Time",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {
      date: true,
      day: true,
      time: true,
    },
    value: "",
  },
  {
    label: "Kill Date",
    labelText: "Kill Date",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Kill Date",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Picked On",
    labelText: "Picked On",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Picked On",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Manufactured On",
    labelText: "Manufactured On",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Manufactured On",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "MFG",
    labelText: "MFG",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "MFG",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Opened",
    labelText: "Opened",
    showLabel: true,
    key: "startDate",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Opened",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Custom Term",
    labelText: "",
    showLabel: true,
    key: "startDate",
    isCustom: true,
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Opened",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
];

const transitionalDateFields = [
  {
    label: "Ready",
    labelText: "Ready",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Ready",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Thaw",
    labelText: "Thaw",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Thaw",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Temper",
    labelText: "Temper",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Temper",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Pull",
    labelText: "Pull",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Pull",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Marinate",
    labelText: "Marinate",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Marinate",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Thaw Through",
    labelText: "Thaw Through",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Thaw Through",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Marinate Until",
    labelText: "Marinate Until",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Marinate Until",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Thaw Until",
    labelText: "Thaw Until",
    showLabel: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Thaw Until",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
    readOnly: true,
  },
  {
    label: "Custom Term",
    labelText: "",
    showLabel: true,
    isCustom: true,
    key: "thawExpiration",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Opened",
    value: "",
    readOnly: true,
  },
];

const endDateFields = [
  {
    label: "Must Use By",
    labelText: "Must Use By",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Must Use By",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Use By",
    labelText: "Use By",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Use By",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Discard",
    labelText: "Discard",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Discard",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Expires",
    labelText: "Expires",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Expires",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Best By",
    labelText: "Best By",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Best By",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Freshest By",
    labelText: "Freshest By",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Freshest By",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Freshest Date",
    labelText: "Freshest Date",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Freshest Date",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Sell By",
    labelText: "Sell By",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Sell By",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Best Before",
    labelText: "Best Before",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Best Before",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Must Sell By",
    labelText: " Must Sell By",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Must Sell By",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Expire",
    labelText: "Expire",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Expire",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Exp",
    labelText: "Exp",
    showLabel: true,
    key: "expirationTime",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Exp",
    format: dateFormat.monthFirst,
    dayFormat: dayFormat.ddd,
    dateIncludes: {},
    value: "",
  },
  {
    label: "Custom Term",
    labelText: "",
    showLabel: true,
    key: "expirationTime",
    isCustom: true,
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Opened",
    value: "",
    readOnly: true,
  },
];

const nutritionFields = [
  {
    label: "Serving size",
    labelText: "Serving size",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: nutritionalKeys.servingSize,
    ...positionAttrbutes,
    placeholder: "Serving size",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 94,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Servings Per Container",
    labelText: "Servings Per Container",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: nutritionalKeys.serving_per_container,
    ...positionAttrbutes,
    placeholder: "Servings Per Container",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Calories",
    labelText: "Calories",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: nutritionalKeys.calories,
    ...positionAttrbutes,
    placeholder: "Calories",
    value: "",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "% Daily Value",
    labelText: "% Daily Value",
    key: nutritionalKeys.dv,
    childKey: false,
    showLabel: true,
    ...positionAttrbutes,
    value: "",
    ...subItemStyle,
  },
  {
    label: "Total Fat(g)",
    labelText: "Total Fat",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "totalFat",
    ...positionAttrbutes,
    placeholder: "Total Fat(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Calcium(mg)",
    labelText: "Total Calcium",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "calcium",
    ...positionAttrbutes,
    placeholder: "Calcium(mg)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Saturated Fat(g)",
    labelText: "Saturated Fat",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "saturatedFat",
    ...positionAttrbutes,
    placeholder: "Saturated Fat(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Trans Fat(g)",
    labelText: "Trans Fat",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "transFat",
    ...positionAttrbutes,
    placeholder: "Trans Fat(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Cholesterol(mg)",
    labelText: "Cholesterol",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "cholesterol",
    ...positionAttrbutes,
    placeholder: "Cholesterol(mg)",
    value: "",
    pValue: "5%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
    ...subItemStyle,
  },
  {
    label: "Carbohydrates(g)",
    labelText: "Carbohydrates",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "carbohydrates",
    ...positionAttrbutes,
    placeholder: "Carbohydrates(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Protein(g)",
    labelText: "Protein",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "protein",
    ...positionAttrbutes,
    placeholder: "Protein(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Dietary Fiber(g)",
    labelText: "Dietary Fiber",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "dietaryFiber",
    ...positionAttrbutes,
    placeholder: "Dietary Fiber(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Total Sugar(g)",
    labelText: "Total Sugar",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "totalSugar",
    ...positionAttrbutes,
    placeholder: "Total Sugar(g)",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Sodium(mg)",
    labelText: "Sodium",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "sodium",
    ...positionAttrbutes,
    placeholder: "Sodium(mg)",
    value: "",
    pValue: "5%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },

  {
    label: "Added Sugar",
    labelText: "Added Sugar",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "addedSugar",
    ...positionAttrbutes,
    placeholder: "Added Sugar",
    value: "",
    pValue: "10%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Calories From Fat",
    labelText: "Calories From Fat",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "caloriesFromFat",
    ...positionAttrbutes,
    placeholder: "Calories From Fat",
    value: "",
    pValue: "20%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Vitamin D",
    labelText: "Vitamin D",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "vitaminD",
    ...positionAttrbutes,
    placeholder: "Vitamin D",
    value: "",
    pValue: "3%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Vitamin D%",
    labelText: "Vitamin D%",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "vitaminDPercent",
    ...positionAttrbutes,
    placeholder: "Vitamin D%",
    value: "",
    pValue: "3%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Iron",
    labelText: "Iron",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "iron",
    ...positionAttrbutes,
    placeholder: "Iron",
    value: "",
    pValue: "3%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Potassium",
    labelText: "Potassium",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "potassium",
    ...positionAttrbutes,
    placeholder: "Potassium",
    value: "",
    pValue: "3%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
  {
    label: "Potassium%",
    labelText: "Potassium%",
    showLabel: false,
    key: "nutritionalInfo",
    childKey: "potassiumPercent",
    ...positionAttrbutes,
    placeholder: "Potassium%",
    value: "",
    pValue: "3%",
    position: {
      x: 0,
      y: 80,
    },
    styles: {
      width: 110,
      height: 20,
    },
    ...subItemStyle,
  },
];

const othersFields = [
  {
    label: "Barcode – Fixed",
    key: "bar_code",
    childKey: false,
    ...positionAttrbutes,
    src: "/images/bar.png",
    value: "",
    styles: {
      width: 60,
      height: 40,
    },
    type: labelTypes.bar_code,
    minWidth: 40,
    minHeight: 30,
  },
  {
    label: "Barcode – Variable",
    key: "bar_code_variable",
    childKey: false,
    ...positionAttrbutes,
    src: "/images/bar.png",
    value: "",
    styles: {
      width: 60,
      height: 40,
    },
    type: labelTypes.bar_code,
    minWidth: 40,
    minHeight: 30,
  },
  {
    label: "QR Code",
    key: "qr_code",
    childKey: false,
    ...positionAttrbutes,
    src: "/images/qr.png",
    value: "",
    styles: {
      width: 60,
      height: 60,
    },
    type: labelTypes.qr_code,
    minWidth: 30,
    minHeight: 30,
  },
  {
    label: "Print Sequence",
    labelText: "Print Sequence",
    showLabel: false,
    readOnly: true,
    key: "print_sequence",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Print Sequence",
    value: "",
    styles: {
      width: 110,
      height: 25,
    },
    type: labelTypes.text_fields,
  },
  {
    label: "Waste Prepped Quantity",
    labelText: "Waste Prepped Quantity",
    showLabel: true,
    readOnly: true,
    key: "waste_prepped_quantity",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Waste Prepped Quantity",
    value: "",
    styles: {
      width: 110,
      height: 25,
    },
    type: labelTypes.text_fields,
  },
  {
    label: "Prepared Temperature",
    labelText: "Prepared Temperature",
    showLabel: true,
    readOnly: true,
    key: "prepared_temperature",
    childKey: false,
    ...positionAttrbutes,
    placeholder: "Prepared Temperature",
    value: "",
    styles: {
      width: 110,
      height: 25,
    },
    type: labelTypes.text_fields,
  },
];

export {
  nutritionalKeys,
  dateFormat,
  dayFormat,
  dateIncludes,
  days,
  defaultFonts,
  endDateFields,
  labelTypes,
  nutritionFields,
  othersFields,
  startDateFields,
  textFields,
  transitionalDateFields,
};
