// import React from "react";
// import { Col, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import useSlider from "../../hooks/useSlider";
// import AdminFooter from "../AdminFooter";
// import Sidebar from "../sidebar/Sidebar";
// const Dashboard = () => {
//   const isSlider = useSlider();
//   return (
//     <div className="mainbox">
//       <Sidebar />
//       <div className={isSlider ? "body-content close" : "body-content open"}>
//         <Row>
//           <Col xl={6} >
//             <h2 className="mainhead font-24">Dashboard</h2>
//           </Col>
//           <Col xl={6}>
//             <div className="d-flex justify-content-end align-items-center">
//               <label className="labelText">Filter by:</label>
//               <select
//                 className="form-select selectfeild"
//                 aria-label="Default select example"
//               >
//                 <option selected>Weekly</option>
//                 <option value="1">One</option>
//                 <option value="2">Two</option>
//                 <option value="3">Three</option>
//               </select>
//             </div>
//           </Col>
//         </Row>

//         <div className="dash-widget ">
//           <Row >
//             <Col xl={9}>
//               <Row>
//                 <Col xl={6} lg={6} md={6}>
//                   <div className="widgetcard">
//                     <div className="value">
//                       <p>Total users</p>
//                       <h4>{2562}</h4>
//                     </div>
//                     <div className="icon icon-two">
//                       <img
//                         src="/images/curve.svg"
//                         alt="img"
//                         className="img-fluid"
//                       />
//                     </div>
//                   </div>
//                 </Col>
//                 <Col xl={6} lg={6} md={6}>
//                   <div className="widgetcard">
//                     <div className="value">
//                       <p>Total Restaurent</p>
//                       <h4>{2562}</h4>
//                     </div>
//                     <div className="icon icon-one">
//                       <img
//                         src="/images/curve.svg"
//                         alt="img"
//                         className="img-fluid"
//                       />
//                     </div>
//                   </div>
//                 </Col>
//                 <Col xl={6} lg={6} md={6}>
//                   <div className="widgetcard">
//                     <div className="value">
//                       <p>Total Products</p>
//                       <h4>{2562}</h4>
//                     </div>
//                     <div className="icon icon-three">
//                       <img
//                         src="/images/curve.svg"
//                         alt="img"
//                         className="img-fluid"
//                       />
//                     </div>
//                   </div>
//                 </Col>
//                 <Col xl={6} lg={6} md={6}>
//                   <div className="widgetcard">
//                     <div className="value">
//                       <p>Total Transactions</p>
//                       <h4>{2562}</h4>
//                     </div>
//                     <div className="icon icon-four">
//                       <img
//                         src="/images/curve.svg"
//                         alt="img"
//                         className="img-fluid"
//                       />
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </Col>
//             <Col xl={3}>
//               <div className="revenewImg">
//                 <img src="/images/revenew.png" className="img-fluid"></img>
//               </div>
//             </Col>
//           </Row>
//           <div className="tableOuter">
//             <Row>
//               <Col xl={6}>
//                 <p className="font-20">Recently Added Users</p>
//               </Col>
//               <Col xl={6}>
//                 <p className="mb-0 text-end ">
//                   <Link className="text-decoration-none linktext">
//                     View All
//                   </Link>
//                 </p>
//               </Col>
//             </Row>
//             <div className="table-responsive tableOut">
//               <table className="table tableDashboard">
//                 <thead className="thead-dark">
//                   <tr>
//                     <th >Customer ID</th>
//                     <th >User Name</th>
//                     <th >Name</th>
//                     <th >Email </th>
//                     <th >Roles</th>
//                     <th >Added </th>
//                     <th >Action </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>#34567821</td>
//                     <td>@wadewarren</td>
//                     <td>Wade Warren</td>
//                     <td>wadewarren@gmail.com</td>
//                     <td>User</td>
//                     <td>05/29/2024</td>
//                     <td>
//                       <img
//                         src="/images/eye.svg"
//                         className="img-fluid actionIcon"
//                       ></img>
//                       <img
//                         src="/images/Edit.svg"
//                         className="img-fluid actionIcon"
//                       ></img>
//                       <img
//                         src="/images/Delete.svg "
//                         className="img-fluid actionIcon"
//                       ></img>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>#34567821</td>
//                     <td>@wadewarren</td>
//                     <td>Wade Warren</td>
//                     <td>wadewarren@gmail.com</td>
//                     <td>User</td>
//                     <td>05/29/2024</td>
//                     <td>
//                       <img
//                         src="/images/eye.svg"
//                         className="img-fluid actionIcon"
//                       ></img>
//                       <img
//                         src="/images/Edit.svg"
//                         className="img-fluid actionIcon"
//                       ></img>
//                       <img  
//                         src="/images/Delete.svg "
//                         className="img-fluid actionIcon"
//                       ></img>
//                     </td>
//                   </tr>
                
//                   </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//       <AdminFooter />
//     </div>
//   );
// };

// export default Dashboard;




import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import useSlider from "../../hooks/useSlider";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import { getDashboardData, getUser } from "../../services/services"; 
import { Link, useLocation } from "react-router-dom";
import { constant } from "../../utils/constants";
import moment from "moment";
import { Bar } from "react-chartjs-2";

const Dashboard = () => {
  const [role, setRole] = useState("monthly");
  const [page, setPage] = useState(constant.PAGE_ONE);
    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const isSlider = useSlider();
    const [company, setCompany] = useState("{}");
    let comp = JSON?.parse(company);


    const options = {
      responsive: true,
      plugins: {
        legend: false,
        title: {
          display: true,
          text: "Total Revenue",
        },
      },
    };

  // Fetch dashboard data
  const { data: dashboardData, isLoading, error } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: getDashboardData,
  });

  // Fetch user list
  const { data: userData, refetch } = useQuery({
    queryKey: ["user-list", page, limit,  comp?.id, ],
    queryFn: async () => {
      const resp = await getUser(page, limit, search, company?.id, );
      return resp?.data?.data;
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <h2 className="mainhead font-24">Dashboard</h2>
          </Col>
          <Col xl={6}>
            <div className="d-flex justify-content-end align-items-center">
              <label className="labelText">Filter by:</label>
              <select className="form-select selectfeild" aria-label="Default select example"
              value={role}
              onChange={(e) => setRole(e.target.value)}>

                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </Col>
        </Row>

        <div className="dash-widget">
          <Row>
            <Col xl={9}>
              <Row>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Users</p>
                      <h4>{dashboardData?.data?.data?.totalUsers || 0}</h4>
                    </div>
                    <div className="icon icon-two">
                      <img src="/images/curve.svg" alt="img" className="img-fluid" />
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Restaurant</p>
                      <h4>{dashboardData?.data?.data?.totalCompany || 0}</h4>
                    </div>
                    <div className="icon icon-one">
                      <img src="/images/curve.svg" alt="img" className="img-fluid" />
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Transactions</p>
                      <h4>{dashboardData?.data?.data?.totalTrx || 0}</h4>
                    </div>
                    <div className="icon icon-three">
                      <img src="/images/curve.svg" alt="img" className="img-fluid" />
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Revenue</p>
                      <h4>${dashboardData?.data?.data?.totalRevenue}</h4>
                    </div>
                    <div className="icon icon-four">
                      <img src="/images/curve.svg" alt="img" className="img-fluid" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* <Col xl={3}>
               <div className="revenewImg">
                 <img src="/images/revenew.png" className="img-fluid" alt="Revenue Chart" />
               </div>
            </Col> */}

              {/* <Col xl={3}>
                        <Bar options={options} data={data} />
                      </Col> */}
          </Row>
        </div>

        <div className="table-responsive tableOut">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Role</th>
                <th>Added</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userData?.users?.length > 0 ? (
                userData?.users?.map((item, index) => (
                  <tr key={index}>
                    <td>{(page - 1) * limit + (index + 1)}</td>
                    <td>{item?.userId}</td>
                    <td className="text-capitalize">{item?.userInfo?.name}</td>
                    <td>{item?.email}</td>
                    <td className="text-capitalize">{item?.role}</td>
                    <td>{moment(item?.createdAt).format("lll")}</td>
                    <td>
                      <Link to={`../user/${item?._id}`}>
                        <img src="/images/eye.svg" className="img-fluid actionIcon" alt="view"/>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    <h4>No Users Found</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
};

export default Dashboard;
