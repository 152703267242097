import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toBlob } from "html-to-image";
import React, { useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { Rnd } from "react-rnd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import uuid4 from "uuid4";
import * as yup from "yup";
import DropDown from "../../components/DropDown";
import Loader from "../../components/Loader/Loader";
import NavigateBack from "../../components/NavigateBack";
import { validateImageFileType } from "../../helper/helper";
import useDetails from "../../hooks/useDetails";
import {
  addLabel,
  addLabelImage,
  getAllCompany,
  getItems,
  getLabelDetails,
  updateLabel,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { days, defaultFonts, labelTypes } from "../../utils/labelConstants";
import { toastAlert } from "../../utils/SweetAlert";
import Sidebar from "../sidebar/Sidebar";
import "./createLabel.css";
import FormatEditor from "./FormatEditor";
import DateInput from "./label-component/DateInput";
import ImageInput from "./label-component/ImageInput";
import NutritionTable from "./label-component/NutritionTable";
import QRBar from "./label-component/QRBar";
import TextField from "./label-component/TextField";
import LabelSideBar from "./LabelSideBar";
import moment from "moment";

const CreateLabel = () => {
  const imageRef = useRef();
  const childRef = useRef();
  const details = useDetails();
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [item, setItem] = useState([]);
  const [scale, setScale] = useState(2);
  const [snap, setSnap] = useState(false);
  const [preview, setPreview] = useState(false);
  const [width] = useState(state?.name?.split("X")?.[0]);
  const [height] = useState(state?.name?.split("X")?.[1] || 2);
  const [borderChecked, setBorderChecked] = useState(false);
  const [selectedElement, setSelectedElement] = useState("");
  const [subItem, setSubItem] = useState("");
  const [previewCompany, setPreviewCompany] = useState("");
  const [previewItem, setPreviewItem] = useState("");
  const [expirationDay, setExpirationDay] = useState("");
  const [canvasStyle, setCanvasStyle] = useState({
    border: `2px none #42b03a`,
  });
// console.log(state.slug,"data")
const [isRotated, setIsRotated] = useState(false); // State to track rotation

const handleRotateClick = () => {
  setIsRotated(!isRotated); // Toggle rotation state
};

const shouldShowButton = !["circle", "square","day_of_week","blank_2x2"].some(shape => state.slug?.includes(shape));

const [selectedBorderType, setSelectedBorderType] = useState("solid");
const [selectedSide, setSelectedSide] = useState("all");
// console.log(previewCompany,"previewCompany")
const handleStyleChange = (event) => {
const selectedStyle = event.target.value;
setSelectedBorderType(selectedStyle);
applyBorderStyle(selectedStyle, selectedSide);
};

  const handleSideChange = (event) => {
    const selectedSide = event.target.value;
    setSelectedSide(selectedSide);
    applyBorderStyle(selectedBorderType, selectedSide);
  };

  const applyBorderStyle = (style, side) => {
    let newStyle = {
      "border-top-style": "none",
      "border-right-style": "none",
      "border-bottom-style": "none",
      "border-left-style": "none",
      "border-width": "2px",
      "border-color": "#42b03a",
    };

    switch (side) {
      case "all":
        newStyle = {
          ...newStyle,
          "border-top-style": style,
          "border-right-style": style,
          "border-bottom-style": style,
          "border-left-style": style,
        };
        break;
      case "top":
        newStyle["border-top-style"] = style;
        break;
      case "bottom":
        newStyle["border-bottom-style"] = style;
        break;
      case "left":
        newStyle["border-left-style"] = style;
        break;
      case "right":
        newStyle["border-right-style"] = style;
        break;
      case "top-bottom":
        newStyle["border-top-style"] = style;
        newStyle["border-bottom-style"] = style;
        break;
      case "left-right":
        newStyle["border-left-style"] = style;
        newStyle["border-right-style"] = style;
        break;
      default:
        newStyle = {
          ...newStyle,
          "border-top-style": "none",
          "border-right-style": "none",
          "border-bottom-style": "none",
          "border-left-style": "none",
        };
        break;
    }
    setCanvasStyle(newStyle);
  };
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "ITEM",
      drop: (ele, monitor) => {
        if (preview) return;
        const { x, y } = monitor.getClientOffset();
        let parentBounds = childRef?.current?.getBoundingClientRect();
        const elementWidth = parseInt(ele.data.styles.width, 10) * scale;
        const elementHeight = parseInt(ele.data.styles.height, 10) * scale;

        let xPosition = parseInt(
          (x - parentBounds.left - elementWidth / 2) / scale
        );
        let yPosition = parseInt(
          (y - parentBounds.top - elementHeight / 2) / scale
        );
        xPosition = Math.max(
          0,
          Math.min(xPosition, (parentBounds.width - elementWidth) / scale)
        );
        yPosition = Math.max(
          0,
          Math.min(yPosition, (parentBounds.height - elementHeight) / scale)
        );

        if (ele?.data?.type == labelTypes.nutritional_fields) {
          let data = {
            id: uuid4(),
            key: "nutritionalInfo",
            type: ele?.data?.type,
            minHeight: 75,
            minWidth: 80,
            position: {
              x: xPosition,
              y: yPosition,
            },
            styles: {
              width: 94,
              height: 180,
            },
            input: {
              style: {
                fontSize: "10px",
                fontFamily: defaultFonts?.[0],
                textAlign: "left",
                fontWeight: "normal",
              },
            },
            subItem: [
              {
                ...ele?.data,
                id: uuid4(),
              },
            ],
          };

          setItem((pre) => {
            const existingItemIndex = pre.findIndex(
              (i) => i.type === labelTypes.nutritional_fields
            );

            if (existingItemIndex !== -1) {
              const obj = pre[existingItemIndex];
              return [
                ...pre.slice(0, existingItemIndex),
                {
                  ...obj,
                  subItem: [
                    ...obj.subItem,
                    {
                      ...ele?.data,
                      id: uuid4(),
                    },
                  ],
                },
                ...pre.slice(existingItemIndex + 1),
              ];
            }
            return [...pre, data];
          });
        } else {
          const newItem = {
            id: uuid4(),
            ...ele?.data,
            position: {
              x: xPosition,
              y: yPosition,
            },
          };
          setItem((pre) => [...pre, newItem]);
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !preview && !!monitor.canDrop(),
      }),
    }),
    [preview]
  );

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      profile_name: "",
      forAllCompanies: false,
      company: [],
    },
    validationSchema: yup.object().shape({
      profile_name: yup.string().required().label("Profile Name"),
      forAllCompanies: yup.boolean(),
      company: yup.array().when("forAllCompanies", {
        is: (value) => !value,
        then: () => yup.array().min(1).label(t("product.company")),
      }),
    }),
    onSubmit: async () => {
      try {
        setPreview(true);
        let formData = new FormData();
        let blob = await toBlob(imageRef.current);
        formData.append("file", blob, "label-image.png");

        canvasImageMutation.mutate(formData);
      } catch (err) {
        console.log("err", err);
      }
    },
  });

  const canvasImageMutation = useMutation({
    mutationFn: async (formData) => {
      const resp = await addLabelImage(formData);
      return { url: resp?.data?.data?.imageUrl };
    },
    onSuccess: (data) => {
      let body = {
        profile_name: values?.profile_name,
        labelSize: state?._id,
        forAllCompanies: values?.forAllCompanies,
        assignAll: true,
        metaData: { canvasStyle, item },
      };
      if (!values?.forAllCompanies)
        body.companies = values?.company?.map((i) => i.value);
      body.label_image = data?.url;
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => (id ? updateLabel(id, body) : addLabel(body)),
    onSuccess: () => {
      navigate("../label");
    },
  });

  const imageMutation = useMutation({
    mutationFn: async ({ formData, el }) => {
      const resp = await addLabelImage(formData);
      return { url: resp?.data?.data?.imageUrl, el };
    },
    onSuccess: async ({ url, el }) => {
      const newItems = await Promise.all(
        item.map(async (i) => {
          if (i.id === el.id) {
            return { ...i, src: url };
          }
          return i;
        })
      );

      setItem(newItems);
    },
  });

  useQuery({
    queryKey: ["label-details", id],
    queryFn: async () => {
      const resp = !!id && (await getLabelDetails(id));
      let data = resp?.data?.data;
      if (data) {
        setValues({
          ...values,
          profile_name: data?.profile_name,
          forAllCompanies: data?.forAllCompanies,
          company: data?.companies?.map((item) => ({
            value: item?._id,
            label: item?.name,
            domain: item?.uuid,
          })),
        });

        setItem(data?.metaData?.item);
        setCanvasStyle(data?.metaData?.canvasStyle);
        setBorderChecked(
          !data?.metaData?.canvasStyle?.border?.includes("none")
        );
      }

      return true;
    },
  });

  /************************Upload Image functionality****************/
  const handleImageChange = async (e, el) => {
    if (!validateImageFileType(e.target.files[0])) {
      toastAlert("error", t("errorMsg.fileFormat"));
      return;
    }

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    imageMutation.mutate({ formData, el });
  };

  const handleRemoveObject = (id) => {
    const newItems = item.filter((i) => i.id !== id);
    setItem(newItems);
  };

  const updatePosition = (id, deltaX, deltaY) => {
    setItem((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              position: { x: deltaX, y: deltaY },
            }
          : item
      )
    );
  };

  const updateSize = (id, width, height) => {
    setItem((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, styles: { width: width, height: height } }
          : item
      )
    );
  };

  const renderComponent = (fieldType, props) => {
    switch (fieldType) {
      case labelTypes.image:
        return (
          <ImageInput
            handleRemoveObject={handleRemoveObject}
            handleImageChange={handleImageChange}
            {...props}
          />
        );
      case labelTypes.text_fields:
        return (
          <TextField
            {...props}
            setItem={setItem}
            selectedElement={selectedElement}
            handleRemoveObject={handleRemoveObject}
            setSelectedElement={setSelectedElement}
          />
        );
      case labelTypes.start_date_field:
      case labelTypes.end_date_field:
      case labelTypes.transitional_date:
        return (
          <DateInput
            {...props}
            handleRemoveObject={handleRemoveObject}
            setSelectedElement={setSelectedElement}
            setItem={setItem}
            selectedElement={selectedElement}
          />
        );
      case labelTypes.nutritional_fields:
        return (
          <NutritionTable
            {...props}
            handleRemoveObject={handleRemoveObject}
            setSelectedElement={setSelectedElement}
            setItem={setItem}
            preview={preview}
            selectedElement={selectedElement}
            subItem={subItem}
            setSubItem={setSubItem}
          />
        );
      case labelTypes.bar_code:
      case labelTypes.qr_code:
        return <QRBar handleRemoveObject={handleRemoveObject} {...props} />;
      default:
        return <div>Unrecognized type</div>;
    }
  };

  const updateValue = (id, newValue) => {
    setItem((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadCompanyForPreviwe = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
        address: `${item?.corporateAddress?.street}, ${item?.corporateAddress?.city}, ${item?.corporateAddress?.state} ${item?.corporateAddress?.zipCode}, ${item?.corporateAddress?.country}`,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadItem = async (search, loadedOptions, { page }) => {
    let resp =
      previewCompany?.domain &&
      (await getItems(page, constant.PER_PAGE_TEN, previewCompany?.domain));
    let array = (await resp?.data?.data?.items) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.en_name,
        data: item,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  function updateItemValues() {
    if (!previewCompany.domain && !previewItem.value) {
      toastAlert("error", "Please select item to fetch details");
      return;
    }

const previewData = previewItem.data || {};
// console.log(previewData,"previewData")
/*******************Expiration Days Calculation***************/
console.log(previewData.eodExpiration,"LAST");

    // const {
    //   days: thawDays = 0,
    //   hours: thawHours = 0,
    //   mins: thawMins = 0,
    // } = previewData.thawExpiration || {};
    // const {
    //   days: expDays = 0,
    //   hours: expHours = 0,
    //   mins: expMins = 0,
    // } = previewData["expirationTime"] || {};

    // const startDate = new Date();
    // console.log(thawDays, "days");

    // const thawDate = new Date(startDate);
    // if (previewData?.eodThrawExpiration) {
    //   thawDate.setDate(thawDate.getDate() + thawDays);
    // } else {
    //   thawDate.setDate(thawDate.getDate() + thawDays);
    //   thawDate.setHours(thawDate.getHours() + thawHours);
    //   thawDate.setMinutes(thawDate.getMinutes() + thawMins);
    // }

    // const baseDate = thawDays || thawHours || thawMins ? thawDate : startDate;

    // const endDate = new Date(baseDate);
    // if (previewData?.eodExpiration) {
    //   endDate.setDate(endDate.getDate() + expDays);
    // } else {
    //   endDate.setDate(endDate.getDate() + expDays);
    //   endDate.setHours(endDate.getHours() + expHours);
    //   endDate.setMinutes(endDate.getMinutes() + expMins);
    // }

    const {
      days: thawDays = 0,
      hours: thawHours = 0,
      mins: thawMins = 0,
    } = previewData.thawExpiration || {};
    const {
      days: expDays = 0,
      hours: expHours = 0,
      mins: expMins = 0,
    } = previewData["expirationTime"] || {};
    
    const startDate = new Date();
    
    const thawDate = new Date(startDate);
    if (previewData?.eodThrawExpiration) {
      // If EOD thaw is checked, set thaw time to 11:59 PM
      thawDate.setHours(23, 59, 59, 999);
    } else {
      thawDate.setDate(thawDate.getDate() + thawDays);
      thawDate.setHours(thawDate.getHours() + thawHours);
      thawDate.setMinutes(thawDate.getMinutes() + thawMins);
    }
    
    // Expiration should be calculated from thaw completion time
    const baseDate = thawDays || thawHours || thawMins ? thawDate : startDate;
    
    // Calculate expiration date
    const endDate = new Date(baseDate);
    if (previewData?.eodExpiration) {
      if (expDays === 0) {
        // If expiration is 0 days, expire at the end of the next day
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(23, 59, 59, 999);
      } else {
        endDate.setDate(endDate.getDate() + expDays);
        endDate.setHours(23, 59, 59, 999);
      }
    } else {
      endDate.setDate(endDate.getDate() + expDays);
      endDate.setHours(endDate.getHours() + expHours);
      endDate.setMinutes(endDate.getMinutes() + expMins);
    }
    
    const expirationLabel =
      expDays === 0 && previewData?.eodExpiration
        ? "EOD"
        : endDate.toLocaleString();
    
    console.log("Expiration:", expirationLabel);
    

setExpirationDay(moment(endDate)?.format("ddd"));

    return setItem(
      item.map((field) => { 
        const { key, childKey } = field;

        if (childKey && previewData[key]?.hasOwnProperty(childKey)) {
          const value = previewData[key][childKey];
          if (childKey === "sellingPrice") {
            return { ...field, value: `$${value}` };
          }
          if (childKey === "netWeight") {
            return { ...field, value: `${value}g` };
          }
          return { ...field, value };
        }

        if (key) {
          if (key === "nutritionalInfo") {
            return {
              ...field,
              subItem: field?.subItem?.map((sub) => {
                const { key, childKey } = sub;
                if (childKey && previewData[key]?.hasOwnProperty(childKey)) {
                  const value = previewData[key][childKey];
                  if (
                    childKey == "totalFat" ||
                    childKey == "saturatedFat" ||
                    childKey == "transFat" ||
                    childKey == "carbohydrates" ||
                    childKey == "protein" ||
                    childKey == "dietaryFiber" ||
                    childKey == "totalSugar" ||
                    childKey == "addedSugar"
                  ) {
                    return {
                      ...sub,
                      value: `${value}g`,
                    };
                  }
                  if (
                    childKey == "cholesterol" ||
                    childKey == "sodium" ||
                    childKey == "potassium"
                  ) {
                    return {
                      ...sub,
                      value: `${value}mg`,
                    };
                  }
                  if (childKey == "vitaminD" || childKey == "iron") {
                    return {
                      ...sub,
                      value: `${value}mcg`,
                    };
                  }
                  return { ...sub, value };
                }
                return sub;
              }),
            };
          }
          if (key === "address" && previewCompany.hasOwnProperty(key)) {
            return { ...field, value: previewCompany[key] };
          }
          if (key === "user_name") {
            return { ...field, value: details?.userInfo.name };
          }
          if (key === "startDate") {
            return { ...field, value: new Date() };
          }
          if (key === "thawExpiration") {
            const { days = 0, hours = 0, mins = 0 } = previewData[key] || {};
            const startDate = new Date();

            if (days === 0 && hours === 0 && mins === 0) {
              return {
                ...field,
                value: false,
                eod: previewData?.eodThrawExpiration ? true : false,
              };
            }

            const thawDate = new Date(startDate);
            if (previewData?.eodThrawExpiration) {
              thawDate.setDate(thawDate.getDate() + days);
            } else {
              thawDate.setDate(thawDate.getDate() + days);
              thawDate.setHours(thawDate.getHours() + hours);
              thawDate.setMinutes(thawDate.getMinutes() + mins);
            }

return {
...field,
value: thawDate,
eod: previewData?.eodThrawExpiration ? true : false,
};
}
if (key === "expirationTime") {
    if (expDays === 0 && expHours === 0 && expMins === 0) {
      return {
        ...field,
        value: previewData?.eodExpiration ? "EOD" : true, 
        eod: previewData?.eodExpiration ? true : false,
      };
    }
  
    return {
      ...field,
      value: endDate,
      eod: previewData?.eodExpiration ? true : false,
    };
  }
  
if (key == "print_sequence") {
return {
...field,
value: `1 of 5`,
};
}
if (previewData.hasOwnProperty(key)) {
return { ...field, value: previewData[key] };
}
}
return field;
})
);
}
return (
  <div className="mainbox">
    <Sidebar sideBar={true} showFullHeader={true} />
    <section className={"body-content close aa"}>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <NavigateBack>
            {id ? "Update Label Profile" : "Create Label Profile"}
          </NavigateBack>

          <div className="all_Customer d-flex align-items-center">
            <div className="form-group mx-3">
              <input
                type="checkbox"
                id="all_customers"
                value={values?.forAllCompanies}
                onChange={(e) => {
                  handleChange(e);
                }}
                checked={values?.forAllCompanies == true}
                name="forAllCompanies"
              />
              <label htmlFor="all_customers">
                <span className="ms-2 allcustomer_Txt">all our customers</span>
              </label>
            </div>

            <button
              className="userBtn btn-theme fontSize18 me-2"
              onClick={handleSubmit}
              type="button"
            >
              {id ? "Update" : "Save"}
            </button>
          </div>
        </div>
        <div className="basic_Info upload_Logo">
          {!values?.forAllCompanies && (
            <div className="mb-2">
              <label htmlFor="name" className="companyLabel">
                {t("user.company")} <span className="text-danger">*</span>
              </label>
              <DropDown
                placeholder={t("user.selectCompany")}
                id="company"
                loadOptions={loadCompany}
                isClearable={false}
                isMulti={true}
                onChange={(e) => {
                  setFieldValue("company", e);
                }}
                value={values?.company}
                onBlur={() => setFieldTouched("company", true)}
              />
              <small className="text-danger ms-1">
                {touched?.company && errors?.company}
              </small>
            </div>
          )}
          <div>
            <label htmlFor="profile_name" className="companyLabel">
              Profile Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="profile_name"
              name="profile_name"
              value={values?.profile_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className="commonInput fontSize14"
              placeholder="Enter Profile Name"
            />
            <small className="text-danger ms-1">
              {touched?.profile_name && errors?.profile_name}
            </small>
          </div>

          <div className="snap_Grid_Parent">
            <div className="row">
              {/* Snap to Grid */}
              <div className="col-md-3">
                <div className="center d-flex align-items-center">
                  <label htmlFor="grid" className="snapgrid_Label fontSize14">
                    Snap to Grid:
                  </label>
                  <input
                    type="checkbox"
                    value={!snap}
                    onChange={() => setSnap(!snap)}
                  />
                </div>
              </div>

              {/* Border */}
              <div className="col-md-3">
                <div className="center d-flex align-items-center">
                  <label htmlFor="border" className="snapgrid_Label fontSize14">
                    Border:
                  </label>
                  <input
                    type="checkbox"
                    checked={borderChecked}
                    onChange={() => {
                      if (borderChecked) {
                        setBorderChecked(false);
                        setCanvasStyle({ border: `2px none #42b03a` });
                      } else {
                        setBorderChecked(true);
                        setCanvasStyle({ border: `2px solid #42b03a` });
                      }
                    }}
                    id="border"
                  />
                </div>
              </div>

              {/* Preview */}
              <div className="col-md-3">
                <div className="center d-flex align-items-center">
                  <label
                    htmlFor="preview"
                    className="snapgrid_Label fontSize14"
                  >
                    Preview:
                  </label>
                  <input
                    type="checkbox"
                    value={!preview}
                    checked={preview}
                    onChange={() => setPreview(!preview)}
                  />
                </div>
              </div>

              {shouldShowButton && (
                <div className="col-md-3">
                  <div className=" ">
                    <button
                      onClick={handleRotateClick}
                      className="userBtn btn-theme fontSize18 me-2"
                    >
                      {isRotated ? "Reset Rotation" : "Rotate 90°"}
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Preview Dropdowns and Button */}
            {preview && (
              <div className="row mt-3">
                <div className="col-md-5">
                  <label htmlFor="company" className="companyLabel">
                    Company
                  </label>
                  <DropDown
                    placeholder={t("user.selectCompany")}
                    id="company"
                    loadOptions={loadCompanyForPreviwe}
                    isClearable={false}
                    onChange={(e) => {
                      setPreviewCompany(e);
                      setPreviewItem("");
                    }}
                    value={previewCompany}
                  />
                </div>
                <div className="col-md-5">
                  <label htmlFor="item" className="companyLabel">
                    Item
                  </label>
                  <DropDown
                    placeholder={"Select Item"}
                    id="item"
                    depends={previewCompany?.domain}
                    loadOptions={loadItem}
                    isClearable={false}
                    onChange={setPreviewItem}
                    value={previewItem}
                  />
                </div>
                <div className="col-md-2 d-flex align-items-end">
                  <button
                    className="userBtn btn-theme fontSize18"
                    onClick={updateItemValues}
                    type="button"
                  >
                    Set Data
                  </button>
                </div>
              </div>
            )}
          </div>
          {borderChecked && (
            <div className="row mb-4">
              <div className="col-md-6">
                <div className="feature_Range">
                  <label htmlFor="name" className="companyLabel">
                    Border Style
                  </label>
                  <select
                    name="state"
                    id="state"
                    className="state_Select"
                    onChange={handleStyleChange}
                    value={selectedBorderType}
                  >
                    <option value="solid">Solid</option>
                    <option value="dotted">Dotted</option>
                    <option value="dashed">Dashed</option>
                    <option value="groove">Grooved</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feature_Range">
                  <label htmlFor="name" className="companyLabel">
                    Border Side
                  </label>
                  <select
                    name="side"
                    id="side"
                    className="state_Select"
                    onChange={handleSideChange}
                    value={selectedSide}
                  >
                    <option value="all">All Sides</option>
                    <option value="top">Top</option>
                    <option value="bottom">Bottom</option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                    <option value="top-bottom">Top & Bottom</option>
                    <option value="left-right">Left & Right</option>
                  </select>
                </div>
              </div>
            </div>
          )}

          <div className="label_Features">
            <div className="row">
              <div className="col-lg-3 pe-lg-0">
                <div className="features">
                  <div className="featureTxt_Parent">
                    <h2 className="fontSize24">Label Features</h2>
                  </div>
                  <LabelSideBar />
                </div>
              </div>

              <div className="col-lg-6 p-lg-0">
                <div
                  className="label_Preview features"
                  style={{ overflow: "scroll" }}
                >
                  <div className="featureTxt_Parent d-flex justify-content-between align-items-center">
                    <h2 className="fontSize24">Label Preview</h2>
                    <input
                      type="range"
                      className="range_Input"
                      value={scale}
                      min={1}
                      max={3}
                      step={0.1}
                      onChange={(e) => setScale(e.target.value)}
                    />
                  </div>
                  <div
                    className="feature_Editor_Child d-flex justify-content-center"
                    ref={imageRef}
                    style={{ height: `${height * scale + 0.5}in` }}
                  >
                    <div
                      className="label_Preview_Child"
                      ref={drop}
                      style={{
                        backgroundColor: isOver ? "lightgrey" : "white",
                        height: isRotated ? `${width}in` : `${height}in`, 
                        width: isRotated ? `${height}in` : `${width}in`, 
                        transform: `scale(${scale}) `,
                        transformOrigin: "top",
                        ...canvasStyle,
                        position: "relative",
                        ...(state?.slug == constant.LABEL_TYPE.circle_2
                          ? { borderRadius: "50%", overflow: "hidden" }
                          : {}),
                      }}
                    >
                      {(state?.slug == constant.LABEL_TYPE.temper_evident_2x4 ||
                        state.slug ==
                          constant.LABEL_TYPE.temper_evident_2x6) && (
                        <div
                          style={{
                            width: "23px",
                            height: "100%",
                            backgroundColor: "#ff0000",
                            color: "#ffffff",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            writingMode: "vertical-rl",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "15px 0",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <div>Seal Secure</div>
                          {state.slug ==
                            constant.LABEL_TYPE.temper_evident_2x6 && (
                            <div>Seal Secure</div>
                          )}
                        </div>
                      )}
                      {(state?.slug == constant.LABEL_TYPE.day_of_week_2x1 ||
                        state?.slug == constant.LABEL_TYPE.day_of_week_2x2) && (
                        <div>
                          <div
                            className="d-flex"
                            style={{
                              position: "absolute",
                              zIndex: 1,
                              left: "50%",
                              ...(state?.slug ==
                              constant.LABEL_TYPE.day_of_week_2x1
                                ? {
                                    bottom: "0",
                                    transform: "translate(-50%, -80%)",
                                  }
                                : {
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                  }),
                            }}
                          >
                            {days.map((day, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "0 2px",
                                  ...(preview &&
                                  previewItem?.data?._id &&
                                  expirationDay != day?.value
                                    ? {
                                        backgroundColor: "#000",
                                        color: "#000",
                                      }
                                    : {}),
                                }}
                              >
                                <div
                                  style={{
                                    ...day.style,
                                    width: "22px",
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    ...(preview &&
                                    previewItem?.data?._id &&
                                    expirationDay != day?.value
                                      ? {
                                          backgroundColor: "#000",
                                          color: "#000",
                                        }
                                      : {}),
                                  }}
                                >
                                  {day.label}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div
                            style={{
                              color: "#007200",
                              fontWeight: "bold",
                              fontSize: "8px",
                              textAlign: "end",
                              zIndex: 1,
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                              transform: "translate(-15%, -20%)",
                            }}
                          >
                            Prep Guardian
                          </div>
                        </div>
                      )}

                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                        }}
                        ref={childRef}
                        className="child-container"
                      >
                        {item?.map((element) => {
                          const props = {
                            element,
                            preview,
                            handleChange: (newValue, id) =>
                              updateValue(id, newValue),
                          };
                          return (
                            <Rnd
                              key={element.id}
                              default={{
                                ...element.position,
                                ...element.styles,
                              }}
                              scale={Number(scale)}
                              disableDragging={preview}
                              dragGrid={snap ? [20, 20] : [1, 1]}
                              onDragStop={(e, d) => {
                                updatePosition(element.id, d.x, d.y);
                              }}
                              onResizeStop={(
                                e,
                                direction,
                                ref,
                                delta,
                                position
                              ) => {
                                updateSize(
                                  element.id,
                                  ref.offsetWidth,
                                  ref.offsetHeight
                                );
                                {console.log('offsetHeight :>> ', ref.offsetHeight,ref.offsetWidth);}
                                updatePosition(
                                  element.id,
                                  position.x,
                                  position.y
                                );
                              }}
                              enableResizing={{
                                topRight: false,
                              }}
                              minWidth={element?.minWidth || 250}
                              minHeight={element?.minHeight || 20}
                              bounds="parent"
                            >
                              {renderComponent(element.type, props)}
                            </Rnd>
                          );
                        })}
                      </div>

                      {(state?.slug == constant.LABEL_TYPE.temper_evident_2x4 ||
                        state.slug ==
                          constant.LABEL_TYPE.temper_evident_2x6) && (
                        <div
                          style={{
                            width: "23px",
                            height: "100%",
                            backgroundColor: "#ff0000",
                            color: "#ffffff",
                            display: "flex",
                            padding: "15px 0",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                            writingMode: "vertical-rl",
                            fontSize: "14px",
                            fontWeight: "bold",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            transform: "rotate(180deg)",
                            zIndex: 1,
                          }}
                        >
                          {state.slug ==
                            constant.LABEL_TYPE.temper_evident_2x6 && (
                            <div>Tamper Evident</div>
                          )}
                          <div>Tamper Evident</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 ps-lg-0">
                <FormatEditor
                  selectedElement={selectedElement}
                  setItem={setItem}
                  item={item}
                  preview={preview}
                  subItem={subItem}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {(mutation.isPending ||
      canvasImageMutation.isPending ||
      imageMutation.isPending) && <Loader />}
  </div>
);
};

export default CreateLabel;
