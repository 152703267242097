import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import Pagination from "../../utils/Pagination";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteUser,
  downloadUserExcel,
  exportUserExcel,
  getAllCompany,
  getCompanyBrands,
  getUser,
  handleUserImport,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { Search } from "../../SvgIcons/allIcons";
import moment from "moment";
import DeleteModal from "../../components/DeleteModal";
import { toastAlert } from "../../utils/SweetAlert";
import { downloadFile, noSpecialChars } from "../../helper/helper";
import { FaDownload, FaFileExport, FaFileImport } from "react-icons/fa";
import FileModal from "../../components/FileModal";

const Usermanagement = () => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);
  const [role, setRole] = useState("all");
  const isSlider = useSlider();
  const [company, setCompany] = useState("{}");
  let comp = JSON?.parse(company);
  const [show, setShow] = useState(false);
  

  const [brand, setBrand] = useState("");

  const { data, refetch } = useQuery({
    queryKey: ["user-list", page, limit, role, comp?.id, brand],
    queryFn: async () => {
      const resp = await getUser(page, limit, search, role, comp?.id, brand);
      return resp?.data?.data;
    },
  });

  

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteUser(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const { data: companyList } = useQuery({
    queryKey: ["company-list"],
    queryFn: async () => {
      const resp = await getAllCompany(1, 50);
      return resp?.data?.data;
    },
  });

  const { data: brandList } = useQuery({
    queryKey: ["company-details", comp?.domain],
    queryFn: async () => {
      const resp = comp?.domain && (await getCompanyBrands(comp?.domain));
      return resp?.data?.data ?? [];
    },
  });

  const fileUploadMutation = useMutation({
      mutationFn: ({ formData, domain }) => handleUserImport(formData, domain),
      onSuccess: (resp) => {
        toastAlert("success", resp?.data?.message);
        refetch();
      },
    });
  
    const handleAction = async (data) => {
      try {
        if (data?.type === constant.FILE_ACTION.IMPORT) {
          const formData = new FormData();
          formData.append("file", data?.file);
          fileUploadMutation.mutate({ formData, domain: data?.company?.domain });
        } else {
          const domain = data?.company?.domain;
          // console.log(domain);
          // console.log(comp?.id,brand,role,"datasss");
          let param={
            companyId:comp?.id,
            role:role,
            // brandId:brand
          }
          const response =
            data?.type === constant.FILE_ACTION.EXPORT
              ? await exportUserExcel({domain:domain,params:param})
              : await downloadUserExcel(domain);
  
          downloadFile(response?.data, `${domain}_user.xlsx`);
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setShow(false);
      }
    };
 
    

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>

        <div className="d-flex align-items-center justify-content-between">
        <h2 className="mainhead font-24">{t("user.userManagement")}</h2>
          <div className="d-flex align-items-center gap-2">
            <button className="userBtn btn-theme me-2"
                          onClick={() => {
                            setShow({ company, type: constant.FILE_ACTION.DOWNLOAD });
                          }}
                          title="Download Sample"
                        >
                          <FaDownload/>
                        </button>
            
                        <label
                          className="userBtn btn-theme me-2"
                          role="button"
                          htmlFor="import-catergory"
                          title="Import"
                        >
                          <FaFileImport/>
                        </label>
                        <button
                          className="userBtn btn-theme me-2"
                          title="Export"
                          onClick={() => {
                            if (!data?.users?.length) {
                              toastAlert("error", "User not found to export");
                              return;
                            }
                            setShow({ company, type: constant.FILE_ACTION.EXPORT });
                          }}
                        >
                          <FaFileExport/>
                        </button>
                        <input
                          type="file"
                          className="d-none"
                          id="import-catergory"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={(e) => {
                            if (
                              e.target.files[0]?.type !==
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                              toastAlert("error", t("errorMsg.xlsxFormat"));
                              return;
                            }
                            setShow({
                              company,
                              type: constant.FILE_ACTION.IMPORT,
                              file: e.target.files[0],
                            });
                          }}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                        />
           <Link className="userBtn btn-theme" to="../addNewUser">
              {t("user.addNewUser")}
            </Link>
          </div>
        </div>
        <Row className="align-items-center">
          <Col md={8}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-3">
                <label className="labelText">{t("user.role")}:</label>
                <select
                  className="form-select selectfeild"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="all">{t("user.all")}</option>
                  <option value="admin">{t("user.admin")}</option>
                  <option value="manager">{t("user.manager")}</option>
                  <option value="user">{t("user.user")}</option>
                </select>
              </div>
              <div className="d-flex align-items-center me-3">
                <label className="labelText text-nowrap">
                  {t("user.company")}:
                </label>
                <select
                  className="form-select selectfeild"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                    setBrand("");
                  }}
                >
                  {companyList?.companies &&
                  companyList?.companies?.length > 0 ? (
                    <>
                      <option value="{}">{t("user.all")}</option>
                      {companyList?.companies?.map((item, index) => {
                        return (
                          <option
                            value={JSON.stringify({
                              id: item?._id,
                              domain: item?.uuid,
                            })}
                            key={index}
                          >
                            {item?.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value="{}">{t("user.noOptions")}</option>
                  )}
                </select>
              </div>
              <div className="d-flex align-items-center me-3">
                <label className="labelText text-nowrap">
                  {t("user.brand")}:
                </label>
                <select
                  className="form-select selectfeild"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  {brandList?.associatedBrands &&
                  brandList?.associatedBrands?.length > 0 ? (
                    <>
                      <option value="">{t("user.all")}</option>
                      {brandList?.associatedBrands?.map((item, index) => {
                        return (
                          <option value={item?.brand?._id} key={index}>
                            {item?.brand?.name}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <option value="">{t("user.noOptions")}</option>
                  )}
                </select>
              </div>
            </div>
          </Col>
          <Col md={4} className="py-4">
            <div className="sidebarBox">
              <div className="sidebar-box search-form-wrap mb-0">
                <div className="search-form">
                  <div className="form-group">
                    <span className="iconsearch">
                      <Search />
                    </span>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyDown={(e) => {
                        noSpecialChars(e);
                        if (e.key == "Enter" && search.trim() !== "") refetch();
                      }}
                      onKeyUp={(e) =>
                        e.target.value == "" &&
                        e.key == "Backspace" &&
                        refetch()
                      }
                      type="text"
                      className="form-control"
                      placeholder={t("globals.search")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="table-responsive tableOut">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>{t("user.custId")}</th>
                <th>{t("user.name")}</th>
                <th>{t("user.emailAddress")} </th>
                <th>{t("user.role")}</th>
                <th>{t("user.added")} </th>
                <th>{t("globals.action")} </th>
              </tr>
            </thead>
            <tbody>
              {data?.users && data?.users?.length > 0 ? (
                data?.users?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{(page - 1) * limit + (index + 1)}</td>
                      <td>{item?.userId}</td>
                      <td className="text-capitalize">
                        {item?.userInfo?.name}
                      </td>
                      <td>{item?.email}</td>
                      <td className="text-capitalize">{item?.role}</td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        <Link to={`../userdetail/${item?._id}`}>
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <Link to={`../addNewUser?id=${item?._id}`}>
                          <img
                            src="/images/Edit.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                        <img
                          src="/images/Delete.svg "
                          className="img-fluid actionIcon"
                          onClick={() => setShowDelete(item?._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("user.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.users?.length}
        />
      </div>
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />

{!!show && (
        <FileModal
          show={show}
          handleClose={() => setShow(false)}
          handleAction={handleAction}
        />
      )}
    </div>
  );
};

export default Usermanagement;
